jQuery(document).ready(function ($) {
    var $categories = $('.box-carousel-categories .swiper');

    if (!$categories.length) return

    $categories.each(function (index, element) {
        new Swiper(element, {
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 30,
            speed: 600,
            centerInsufficientSlides: true,
            navigation: {
                nextEl: $(element).parent().find(".carousel-navigation .next")[0],
                prevEl: $(element).parent().find(".carousel-navigation .prev")[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1024: {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                },
                1200: {
                    slidesPerView: 6,
                    slidesPerGroup: 6
                }
            }
        });
    });
});