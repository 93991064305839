window.addEventListener("DOMContentLoaded", function () {
    const $gallery = this.document.querySelector(".carousel-gallery .gallery .swiper");

    if (!$gallery) return;

    var swiper = new Swiper(".carousel-gallery .thumbs .swiper", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        centerInsufficientSlides: true,
        watchSlidesProgress: true,
        breakpoints: {
            768: {
                slidesPerView: 5,
            },
        }
    });

    var swiper2 = new Swiper(".carousel-gallery .gallery .swiper", {
        spaceBetween: 0,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
});
