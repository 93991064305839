jQuery(document).ready(function ($) {
    var $products = $(".box-carousel-products .swiper");

    if (!$products.length) return;

    $products.each(function (index, element) {
        var mySwiper = new Swiper(element, {
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            slidesPerView: 2,
            spaceBetween: 10,
            speed: 500,
            // centerInsufficientSlides: true,
            navigation: {
                nextEl: $(element).parent().find(".carousel-navigation .next")[0],
                prevEl: $(element).parent().find(".carousel-navigation .prev")[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                }
            }
        });
    });
});
