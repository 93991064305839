jQuery(document).ready(function ($) {
    var $fancyboxgallery = $('[data-fancybox^="gallery-"]');

    if (!$fancyboxgallery.length) return

    Fancybox.defaults.infinite = 0;
    Fancybox.defaults.Hash = false;

    Fancybox.bind('[data-fancybox^="gallery-"]');
});

jQuery(document).ready(function ($) {
    var $fancyboxvideo = $('[data-fancybox-video]');

    if (!$fancyboxvideo.length) return

    Fancybox.defaults.infinite = 0;
    Fancybox.defaults.Hash = false;

    Fancybox.bind("[data-fancybox-video]");
});